<template>
  <v-btn
    @click="ClickEvent"
    @mouseover="mouseOver"
    class="btn text-capitalize"
    depressed
    style="border-radius: 5px"
    large
    :icon="tipo === 'icon' ? 'icon' : false"
    :text="tipo === 'text' ? 'text' : false"
    :width="width"
    :to="to"
    :loading="loading"
    :height="height ? height : '35px'"
    :color="color"
    :disabled="disabled"
    :block="block"
    :class="tipo"
    :href="href"
    :target="target"
  >
    <v-icon :color="iconColor" v-if="icon">{{ icon }}</v-icon>
    <span v-if="label" class="ml-2 mr-2">{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  props: [
    "label",
    "color",
    "height",
    "width",
    "to",
    "disabled",
    "loading",
    "block",
    "icon",
    "tipo",
    "iconColor",
    "href",
    "target",
  ],
  methods: {
    ClickEvent() {
      this.$emit("ClickEvent");
    },
    mouseOver() {
      this.$emit("mouseOver");
    },
    calculateWidth() {
      this.headers;
    },
  },
};
</script>
<style scoped>
.btn {
  height: 45px !important;
  border-radius: 20px !important;
  -webkit-box-shadow: -2px 5px 3px 1px #25abe6 !important;
  box-shadow: 0px 1px 3px 1px #4d8ccc !important;
  font-family: inter !important;
  font-weight: 600;
}
.primary {
  color: white !important;
  background: #1976d2 !important;
}
.primary:hover {
  background-color: #050c42 !important;
}
.second {
  background: #ddecf7 !important;
}
.second:hover {
  color: white !important;
  background-color: #050c42 !important;
}
.searchIcon {
  background: #1976d2 !important;
  color: white !important;
  -webkit-border-top-right-radius: 80px !important;
  -webkit-border-bottom-right-radius: 80px !important;
  -moz-border-radius-topright: 80px !important;
  -moz-border-radius-bottomright: 80px !important;
  border-top-right-radius: 80px !important;
  border-bottom-right-radius: 80px !important;
  height: 45px !important;
}
.icon {
  -webkit-box-shadow: 5px 5px 0px 5px rgba(255, 255, 255, 0);
  box-shadow: 5px 5px 0px 5px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px 0px #ffffff !important;
}
.text {
  border-radius: 5px !important;
  -webkit-box-shadow: 5px 5px 0px 5px rgba(255, 255, 255, 0);
  box-shadow: 5px 5px 0px 5px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px 0px #ffffff !important;
}
</style>
